<template>
  <div class="qrcode">
    <qrcode-stream 
      :paused="paused" 
      @detect="onDetect" 
      @camera-on="onCameraOn" 
      @camera-off="onCameraOff" 
      @error="onError" 
      :track="paintOutline" 
      :style="{ width: '100%', height: '100%', margin: '0 auto', center: true }">
      <div v-show="showScanConfirmation" class="scan-confirmation">
        <img src="../assets/checkmark.svg" alt="Checkmark" width="128" />
      </div>
      <div v-show="showErrorImage" class="scan-confirmation">
        <img src="../assets/eror.svg" alt="Error" width="128" />
      </div>
    </qrcode-stream>

    <div class="result">
      <p>{{ result }}</p>
    </div>
  </div>
</template>

<style>
.qrcode {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: block;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

@media (max-width: 480px) {
  .qrcode {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.result {
  color: white;
  font-weight: bold;
}
</style>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import axios from 'axios'

export default {
  components: {
    QrcodeStream
  },

  data() {
    return {
      paused: false,
      result: "",
      showScanConfirmation: false,
      showErrorImage: false
    }
  },

  methods: {
    onCameraOn() {
      this.showScanConfirmation = false
      this.showErrorImage = false
    },

    onCameraOff() {
      this.showScanConfirmation = true
      this.showErrorImage = false
    },

    onError: console.error,

    async onDetect(detectedCodes) {
      if (this.paused) return;

      this.paused = true;

      this.result = JSON.stringify(
        detectedCodes.map(code => code.rawValue)[0]
      );

      const url = new URL(window.location.href);
      const type = url.pathname.split("/")[1];
      const id = url.pathname.split("/")[2];
      const guestId = url.pathname.split("/")[3];

      try {
        const data = await axios.post(`https://offismekan-cmsapi.arneca.com/rest/qr-web/${type}/${id}/${guestId}`, {
          data: JSON.parse(this.result)
        });

        if (data.data.result === true) {
          this.result = "QR kodu doğrulandı, lütfen kapıyı kontrol ediniz.";
          this.showScanConfirmation = true;
        } else {
          this.result = data.data.result_message.message;
          this.showErrorImage = true;
        }

        await this.timeout(5000);
      } catch (error) {
        this.result = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
        this.showErrorImage = true;
        console.error(error);
        await this.timeout(5000);
      } finally {
        this.resetScanner();
      }
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    resetScanner() {
      this.result = "";
      this.showScanConfirmation = false;
      this.showErrorImage = false;
      this.paused = false;
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';
        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    logErrors: console.error
  }
}
</script>
