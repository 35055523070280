<template>
  <div v-if="!kvkkAccepted">
    <h2>KVKK Onayı</h2>
    <p>Lütfen kişisel verilerinizin işlenmesine onay verin.</p>
    <button @click="acceptKVKK">Onayla</button>
  </div>
  <div v-else>
    <img alt="Vue logo" src="./assets/EnterpriseLogo.png">
  
  <div style="margin-bottom: 20px; font-size: 20px; font-weight: bold; color: #ffffff; text-align: center; margin-top: 20px; margin-bottom: 20px;">
    Offismekan QR Okuyucu
  </div>
  <QrcodeStream />
  </div>

  
</template>



<script>
import QrcodeStream from './components/QrcodeStream.vue'


export default {
  name: 'App',
  components: {
    QrcodeStream
  },
  data() {
    return {
      kvkkAccepted: true
    }
  },
  // title oluştur
  created() {
    document.title = "Offismekan QR Okuyucu";
  },
  methods: {
    acceptKVKK() {
      this.kvkkAccepted = true;
      // API'ye KVKK onayını gönderin
      this.sendKVKKApproval();
    },
    sendKVKKApproval() {
      // API'ye KVKK onayını gönderen fonksiyon
      // Örneğin, axios kullanarak bir POST isteği yapabilirsiniz
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100%;
  background-color: #4C5B66;
}
</style>
